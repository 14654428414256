import styled from 'styled-components'
import { TABLET_BREAKPOINT } from './breakpoints'

export const AppWrapper = styled.div<{
  scroll?: boolean
  loggedOut?: boolean
  noPaddingTop?: boolean
}>`
  position: relative;
  display: flex;
  flex: 1;
  overflow-y: ${({ scroll }) => (scroll ? 'auto' : 'hidden')};
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.background.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ loggedOut }) => (loggedOut ? '60px' : '0')};

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100vw;
    left: auto;
    padding-top: ${({ loggedOut, noPaddingTop }) =>
      loggedOut && !noPaddingTop ? '70px' : '0'};
  }
`
