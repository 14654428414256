import { memo } from 'react'
import styled, { css } from 'styled-components'
import { TABLET_BREAKPOINT } from './breakpoints'
import { transition1 } from './transformation'

export const MobileMovableWrapper = memo(styled.div<{
  sidebarExpanded?: boolean
  notificationsExpanded?: boolean
}>`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  overflow: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  height: 100%;
  ${transition1}

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ sidebarExpanded }) =>
      sidebarExpanded &&
      css`
        width: 20%;
        left: 80%;
        right: unset;
      `};
    ${({ notificationsExpanded }) =>
      notificationsExpanded &&
      css`
        left: -80%;
        width: 100%;
      `};
  }
`)
