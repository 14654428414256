import styled from 'styled-components'
import {
  DESKTOP_BREAKPOINT,
  PHONE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from 'styles/breakpoints'
import { transition1 } from 'styles/transformation'

export const LoggedOutHeaderWrapper = styled.div<{
  $showBanner?: boolean
  isScrolling?: boolean
  hide?: boolean
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: absolute;
  top: ${({ $showBanner }) => ($showBanner ? '30px' : 0)};
  left: 0px;
  z-index: 100;
  background: ${({ theme }) => theme.background.primary}7C;
  backdrop-filter: blur(80px);
  ${transition1};

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 10px 20px;
    transform: ${({ hide }) =>
      hide ? 'translateY(-60px)' : 'translateY(0px)'};
    height: ${({ hide }) => (hide ? '0px' : '70px')};
  }

  @media screen and (max-width: ${PHONE_BREAKPOINT}px) {
    top: ${({ $showBanner }) => ($showBanner ? '50px' : 0)};
  }
`

export const LoggedOutHeaderContent = styled.div`
  max-width: ${DESKTOP_BREAKPOINT}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LoggedOutHeaderLogoWrapper = styled.div`
  border-radius: 5px;
  padding: 10px 10px 10px 0;
  cursor: pointer;

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 10px;
    margin-right: 10px;
  }

  :hover {
    background: ${({ theme }) => theme.background.tertiary};
  }
`
