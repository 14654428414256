import styled, { css } from 'styled-components'
import { transition1 } from 'styles/transformation'

const handleSizeType = (size) => {
  switch (size) {
    case 'sm':
      return '0px 10px'
    default:
      return '10px 15px'
  }
}

const handleHeight = (fitHeight, size) => {
  if (fitHeight) return '100%'
  if (size == 'sm') return '30px'

  return '45px'
}

export const BaseButton = styled.button<{
  disabled?: boolean
  center?: boolean
  rounded?: boolean
  small?: boolean
  fullWidth?: boolean
  inline?: boolean
  size?: string
  transparent?: boolean
  highlight?: boolean
  fitHeight?: boolean
  fitWidth?: boolean
  withBorder?: boolean
}>`
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `};

  ${({ center }) =>
    center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};

  height: ${({ fitHeight, size }) => handleHeight(fitHeight, size)};
  border-radius: ${({ rounded }) => (rounded ? 20 : 4)}px;
  padding: ${({ size }) => handleSizeType(size)};
  width: ${({ fitWidth, fullWidth }) =>
    fitWidth ? 'fit-content' : fullWidth ? '100%' : 'auto'};
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  align-items: center;
  justify-content: center;
  border: 0;
  ${transition1}

  &:focus,
  &:active {
    outline: 0;
  }

  ${({ inline }) =>
    inline &&
    css`
      flex: 1;
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`

export const ButtonInner = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`
