import styled from 'styled-components'
import { PHONE_BREAKPOINT } from 'styles/breakpoints'

export const LoggedOutBannerWrapper = styled.div`
  width: 100%;
  height: 30px;
  background: ${({ theme }) => theme.highlight};
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${PHONE_BREAKPOINT}px) {
    height: 50px;
    padding: 0 10px;
  }
`

export const LoggedOutBannerWrapperClose = styled.div`
  color: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  cursor: pointer;
`
