import { Icon, ICON_VARIANTS } from 'components/Icon'
import {
  HeaderMobileNavDrawerBlanket,
  HeaderMobileNavDrawerHeader,
  HeaderMobileNavDrawerList,
  HeaderMobileNavDrawerWrapper,
} from 'components/Header/HeaderMobile/HeaderMobileNavDrawer/styles'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { Text } from 'components/Text'
import { FaHandshake } from 'react-icons/fa'
import { useTheme } from 'styled-components'
import { IoMdMoon, IoMdSunny } from 'react-icons/io'
import {
  MdArrowForward,
  MdClose,
  MdPieChart,
  MdQuestionAnswer,
  MdVideoLibrary,
} from 'react-icons/md'
import {
  HiOutlineSearchCircle,
  HiCurrencyDollar,
  HiOfficeBuilding,
} from 'react-icons/hi'
import { Button, BUTTON_VARIANTS } from 'components/Button'
import { useRouter } from 'next/router'
import { themes } from 'styles/themes/themes'
import { useContext } from 'react'
import { PreferenceContext } from 'contexts/PreferenceContext'

export const LoggedOutHeaderNavDrawer = ({ isOpen, setIsOpen }) => {
  const theme = useTheme()
  const router = useRouter()

  const { updateProperty } = useContext(PreferenceContext)

  const changeTheme = async () => {
    const updatedTheme = theme.is_light_mode
      ? themes.find((theme) => theme.name === 'midnight_blue')
      : themes.find((theme) => theme.name === 'classic_light')
    updateProperty('themeConfig', updatedTheme)
  }

  const sections = [
    {
      label: 'Candidates',
      items: [
        // {
        //   label: 'Features',
        //   icon: MdOutlineFeaturedPlayList,
        //   url: '/features',
        // },
        {
          label: 'Search Jobs',
          icon: HiOutlineSearchCircle,
          url: '/search/jobs',
        },
        {
          label: 'Search Companies',
          icon: HiOfficeBuilding,
          url: '/search/companies',
        },
        {
          label: 'Search Salaries',
          icon: HiCurrencyDollar,
          url: '/search/salaries',
        },
        { label: 'Frequently Asked', icon: MdQuestionAnswer, url: '/faq' },
        { label: 'Login', icon: '', url: '/login' },
        { label: 'Join Fintros', icon: '', url: '/join' },
      ],
    },
    {
      label: 'Employers',
      items: [
        { label: 'Hire With Fintros', icon: FaHandshake, url: '/hire' },
        {
          label: 'Frequently Asked',
          icon: MdQuestionAnswer,
          url: '/faq?tab=employer',
        },
        {
          label: 'Demographics',
          icon: MdPieChart,
          url: '/demographics',
        },
        // {
        //   label: 'Pricing',
        //   icon: MdMonetizationOn,
        //   url: '/pricing'
        // },
        {
          label: 'Employer Login',
          icon: '',
          customUrl: 'https://beta.fintros.com/login',
        },
        {
          label: 'Request a Demo',
          icon: '',
          customUrl: 'https://beta.fintros.com/join',
        },
      ],
    },
    {
      label: 'Theme',
      items: [
        {
          label: theme.is_light_mode
            ? 'Try our dark mode!'
            : 'Try our light mode!',
          icon: theme.is_light_mode ? IoMdMoon : IoMdSunny,
          onClick: changeTheme,
        },
      ],
    },
    {
      label: 'Other',
      items: [
        { label: 'Digital Library', icon: MdVideoLibrary, url: '/library' },
      ],
    },
  ]

  const handleLinkClick = (item) => {
    if (item.customUrl) {
      return window.open(item.customUrl, '_blank')
    }

    if (item.onClick) {
      return item.onClick()
    }

    router.push(item.url)
  }

  return (
    <HeaderMobileNavDrawerBlanket
      $isOpen={isOpen}
      onClick={() => setIsOpen(false)}
    >
      <HeaderMobileNavDrawerWrapper
        $isOpen={isOpen}
        onClick={(e) => e.stopPropagation()}
      >
        <HeaderMobileNavDrawerHeader>
          <Icon variant={ICON_VARIANTS.logo} type="fintros" />
          <MdClose onClick={() => setIsOpen(false)} />
        </HeaderMobileNavDrawerHeader>
        <HeaderMobileNavDrawerList>
          <Wrapper padding={20} spacing={40}>
            {sections.map((section, index) => (
              <Wrapper key={index}>
                <Text size="md" color="secondary" uppercase>
                  {section.label}
                </Text>
                <Wrapper spacing={10} mt={20}>
                  {section.items.map((item, index) => {
                    const Icon = item.icon
                    return (
                      <Wrapper key={index}>
                        {item.icon ? (
                          <Wrapper
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={5}
                            cursor="pointer"
                          >
                            <Wrapper
                              display="flex"
                              alignItems="center"
                              cursor="pointer"
                              spacing={10}
                              horizontal
                              onClick={() => handleLinkClick(item)}
                            >
                              <Icon color={theme.secondary} />
                              <Text size="xl" weight="light">
                                {item.label}
                              </Text>
                            </Wrapper>
                            <MdArrowForward color={theme.secondary} />
                          </Wrapper>
                        ) : (
                          <Wrapper mt={20}>
                            <Button
                              size="lg"
                              fullWidth
                              variant={BUTTON_VARIANTS.secondary}
                              onClick={() => handleLinkClick(item)}
                              label={item.label}
                            />
                          </Wrapper>
                        )}
                      </Wrapper>
                    )
                  })}
                </Wrapper>
              </Wrapper>
            ))}
          </Wrapper>
        </HeaderMobileNavDrawerList>
      </HeaderMobileNavDrawerWrapper>
    </HeaderMobileNavDrawerBlanket>
  )
}
