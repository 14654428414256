import _startCase from 'lodash-es/startCase'

export const capitalize = (word) => {
  return word?.charAt(0)?.toUpperCase() + word?.slice(1)
}

export const lowerFirstLetter = (word) =>
  word.charAt(0).toLowerCase() + word.slice(1)

export const capitalizeEveryWord = (word) =>
  word.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))

export const capitalizeReplaceUnderscore = (word) =>
  word.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function (word) {
    return word.toUpperCase()
  })

export const capitalizeReplaceDash = (word) =>
  word.replace(/-/g, ' ').replace(/(?: |\b)(\w)/g, function (word) {
    return word.toUpperCase()
  })

export const capitalizeCamelCase = (word) => _startCase(word)

export const lowercaseReplaceSpace = (word) =>
  word.replace(/\s+/g, '-').toLowerCase()

export const formatEmployeeCount = (employee_count) => {
  switch (employee_count) {
    case 'zero_to_ten':
      return '0 - 10'
    case 'ten_to_fifty':
      return '10 - 50'
    case 'fifty_to_two_fifty':
      return '50 - 250'
    case 'two_fifty_to_five_hundred':
      return '250 - 500'
    case 'five_hundred_to_one_thousand':
      return '500 - 1K'
    case 'one_thousand_to_five_thousand':
      return '1K - 5K'
    case 'five_thousand_to_fifty_thousand':
      return '5K - 50K'
    default:
      return '0 - 10'
  }
}
