import { useContext } from 'react'
import { PrimaryButtonText, PrimaryButtonContainer } from './styles'
import { BaseButtonProps } from '..'
import { ButtonInner } from '../styles'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { ANIMATION_VARIANTS } from 'components/Animation/types'
import { Animation } from 'components/Animation'
import { ThemeContext } from 'styled-components'
import { Icon, ICON_VARIANTS } from 'components/Icon'

/**
 * Renders the Primary Button Component
 * @param {string} label - Label of the button
 * @param {disabled} disabled - Disables events when button is clicked
 * @param {boolean} center - Center the text label of the button
 * @param {boolean} rounded - Make the side of the buttons circular
 * @param {boolean} fullWidth - Stretches the button to full width of the parent
 * @param {boolean} inline - Changes display to inline-flex
 * @param {string} size - Select from either "sm", "md" or "lg"
 * @param {boolean} fullWidth - Stretches 100% of the parent
 * @param {boolean} noWrap - Prevents text from spaning across multiple lines
 */

export const PrimaryButton: React.FC<BaseButtonProps> = ({
  label,
  size = 'md',
  withDropdown,
  loading,
  disabled,
  noWrap,
  children,
  icon,
  brandColors,
  iconPosition = 'right',
  fitWidth,
  ...props
}) => {
  const theme = useContext(ThemeContext)
  return (
    <PrimaryButtonContainer
      size={size}
      disabled={disabled}
      brandColors={brandColors}
      fitWidth={fitWidth}
      {...props}
    >
      <ButtonInner>
        {icon && iconPosition === 'left' && (
          <Wrapper mr={2} display="flex" alignItems="center">
            {icon}
          </Wrapper>
        )}
        {!loading && (
          <PrimaryButtonText
            disabled={disabled}
            size={size}
            noWrap={noWrap}
            brandColors={brandColors}
          >
            {label || children}
          </PrimaryButtonText>
        )}
        {withDropdown ? (
          <Wrapper ml={2} display="flex" alignItems="center">
            <Icon
              variant={ICON_VARIANTS.arrow}
              type="bottom"
              width={7}
              height={4}
              fill={theme.white}
            />
          </Wrapper>
        ) : null}
        {icon && iconPosition === 'right' && (
          <Wrapper ml={2} display="flex" alignItems="center">
            {icon}
          </Wrapper>
        )}
        {loading ? (
          <div>
            <Animation variant={ANIMATION_VARIANTS.loading} size={14} />
          </div>
        ) : null}
      </ButtonInner>
    </PrimaryButtonContainer>
  )
}
