import _isString from 'lodash-es/isString'
import { MutableRefObject, useEffect } from 'react'

const onHandlingRef = (
  ref: MutableRefObject<any>,
  callback: Function,
  event
) => {
  if (ref?.current && !ref?.current?.contains?.(event.target)) {
    callback()
  }
}

const onHandlingElement = (element: Element, callback: Function, event) => {
  if (element && !element.contains(event.target)) {
    callback()
  }
}

const onHandlingClassName = (className: string, callback: Function, event) => {
  const ele = document.getElementsByClassName(className)?.[0]
  onHandlingElement(ele, callback, event)
}

const onHandlingElementId = (id: string, callback: Function, event) => {
  const ele = document.getElementById(id)
  onHandlingElement(ele, callback, event)
}

const stringHandlerMapper = {
  className: onHandlingClassName,
  id: onHandlingElementId,
}
export const useOutsideAlerter = (
  target: MutableRefObject<any> | string | Element,
  callback,
  stringType?: 'className' | 'id'
) => {
  if (_isString(target) && !stringType) {
    throw Error('"stringType" is needed when string is provided as "target"')
  }

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (_isString(target)) {
        return stringHandlerMapper[stringType](target, callback, event)
      }
      if (target instanceof Element) {
        return onHandlingElement(target, callback, event)
      }
      onHandlingRef(target as MutableRefObject<any>, callback, event)
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside, {
      passive: true,
    })
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [target])
}
