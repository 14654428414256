import { Text } from 'components/Text'
import { MdClose } from 'react-icons/md'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { LoggedOutBannerWrapper, LoggedOutBannerWrapperClose } from './styles'

interface LoggedOutBannerProps {
  showBanner?: boolean
  setShowBanner?: (show: boolean) => void
  bannerText?: string
}

/**
 * @param {boolean} showBanner
 * @param {function} setShowBanner
 * @param {string} bannerText
 */

export const LoggedOutBanner: React.FC<LoggedOutBannerProps> = ({
  showBanner,
  setShowBanner,
  bannerText,
}) => {
  return showBanner ? (
    <LoggedOutBannerWrapper>
      <Wrapper display="flex" alignItems="center" spacing={20} horizontal>
        <Text size="lg" color="white">
          {bannerText}
        </Text>
        <LoggedOutBannerWrapperClose onClick={() => setShowBanner(false)}>
          <MdClose fill="white" />
        </LoggedOutBannerWrapperClose>
      </Wrapper>
    </LoggedOutBannerWrapper>
  ) : (
    <></>
  )
}
