import styled, { css } from 'styled-components'
import { defaultShadow } from 'styles/shadows'
import { MdChevronRight } from 'react-icons/md'
import { TABLET_BREAKPOINT } from 'styles/breakpoints'
import { transition1 } from 'styles/transformation'
import { backgroundBlur } from 'styles/backgroundBlur'

export const SearchFilterDropdownWrapper = styled.div<{
  fitWidth?: boolean
}>`
  position: relative;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  box-shadow: none;
  min-width: ${({ fitWidth }) => (fitWidth ? 'auto' : '300px')};
  max-width: 400px;
  & > * + * {
    border-top: 1px solid ${({ theme }) => theme.border};
  }
  .mobileOnly {
    display: none;
    @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
      display: block;
    }
  }
`

export const SearchFilterDropdown = styled.div`
  border-radius: 5px;
  ${backgroundBlur};
`

export const SearchFilterBackground = styled.div`
  background: ${({ theme }) => theme.background.secondary};
`

export const SearchFilterBlanket = styled.div`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
`
export const SearchFilterMenu = styled.div<{
  leftAlign?: boolean
  noCursor?: boolean
  useMobile?: boolean
  fitContent?: boolean
  fadeIn?: boolean
  isOpen?: boolean
}>`
  ${defaultShadow};
  position: absolute;
  z-index: 100;
  min-width: ${({ fitContent }) => (fitContent ? 'fit-content' : '200px')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  cursor: pointer;

  ${({ leftAlign, useMobile }) =>
    useMobile
      ? css`
          width: 360px;
          left: -5px;
        `
      : leftAlign
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};

  ${({ noCursor }) =>
    noCursor &&
    css`
      cursor: auto;
    `};

  ${({ fadeIn, isOpen }) =>
    fadeIn && isOpen
      ? css`
          ${transition1};
          transform: translateY(0px);
        `
      : css`
          ${transition1};
          transform: translateY(5px);
        `};
`

export const SearchFilterButtonWrapper = styled.div<{
  hideMobile?: boolean
  useMobile?: boolean
}>`
  position: ${({ useMobile }) => (useMobile ? 'unset' : 'relative')};
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  button,
  svg {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ hideMobile }) =>
      hideMobile &&
      css`
        display: none;
      `};
  }
`

export const SearchFilterTrigger = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  text-transform: capitalize;
  width: 100%;
`

export const AdditionalFilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  :hover {
    background: ${({ theme }) => theme.background.tertiary};
  }
`

export const AdditionalFilterCaret = styled(MdChevronRight)<{ open?: boolean }>`
  ${transition1}

  ${({ open }) =>
    open
      ? css`
          color: ${({ theme }) => theme.highlight};
          transform: rotate(90deg);
        `
      : css`
          color: ${({ theme }) => theme.primary};
          transform: rotate(0deg);
        `};
`

export const AdditionalFilterBody = styled.div<{ open?: boolean }>`
  overflow-y: hidden;
  height: 0;
  ${transition1}

  ${({ open }) =>
    open &&
    css`
      height: auto;
      border-top: 1px solid ${({ theme }) => theme.border};
      padding: 10px;
    `};
  & > * + * {
    border-top: 1px solid ${({ theme }) => theme.border};
  }
`

export const AdditionalFilterOption = styled.div`
  padding: 6px;
`

export const SearchFilterLoadingOverlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.background.secondary}CC;
  pointer-events: none;
  border-radius: 5px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: ${({ theme }) => theme.primary};
  }
`
