import { LoadingAnimation } from './Loading'
import { AnimationProps } from './types'

export const Animation: React.FC<AnimationProps> = ({ variant, ...props }) => {
  const variants = {
    loading: <LoadingAnimation {...props} />,
  }

  return variants[variant]
}
