import Link from 'next/link'

export const RedirectButtonWrapper: React.FC<{
  children: React.ReactNode
  href?: string
  target?: string
}> = ({ children, href = null, target = null }) => {
  if (!href) return <>{children}</>

  return (
    <Link
      href={href}
      passHref={!!target}
      target={target}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Link>
  )
}
