import { useModalContext } from 'contexts/ModalContext'
import { MODAL_TYPES } from 'contexts/types'
import dynamic from 'next/dynamic'

const ThemeSelectionModal = dynamic(() =>
  import('./ThemeSelectionModal').then((res) => res.ThemeSelectionModal)
)
const ApplyJobModal = dynamic(() =>
  import('./ApplyJobModal').then((res) => res.ApplyJobModal)
)
const ResumeUploadModal = dynamic(() =>
  import('./ResumeUploadModal').then((res) => res.ResumeUploadModal)
)
const PhoneVerificationModal = dynamic(() =>
  import('./PhoneVerficationModal').then((res) => res.PhoneVerificationModal)
)
const SubmitFeedbackModal = dynamic(() =>
  import('./SubmitFeedbackModal').then((res) => res.SubmitFeedbackModal)
)
const AllUploadsModal = dynamic(() =>
  import('./AllUploadsModal').then((res) => res.AllUploadsModal)
)
const JobShareModal = dynamic(() =>
  import('./JobShareModal').then((res) => res.JobShareModal)
)

const ResumePDFPreviewModal = dynamic(() =>
  import('./ResumePDFPreviewModal').then((res) => res.ResumePDFPreviewModal)
)

export const Modals = () => {
  const {
    [MODAL_TYPES.SHOW_JOB_APPLICATION]: isApplicationModalOpen,
    [MODAL_TYPES.SHOW_THEME_SELECTION]: isThemeSelectionModalOpen,
    [MODAL_TYPES.SHOW_RESUME_UPLOAD]: isResumeUploadMOdalOpen,
    [MODAL_TYPES.SHOW_PHONE_VERIFICATION]: isPhoneVerificationModalOpen,
    [MODAL_TYPES.SHOW_SUBMIT_FEEDBACK]: isSubmitFeedbackOpen,
    [MODAL_TYPES.SHOW_ALL_UPLOAD]: isAllUploadsModalOpen,
    [MODAL_TYPES.SHOW_JOB_SHARE]: isJobShareModalOpen,
    [MODAL_TYPES.SHOW_RESUME_PDF_PREVIEW]: isResumePDFPreviewModalOpen,
  } = useModalContext()

  return (
    <>
      {isApplicationModalOpen && <ApplyJobModal />}
      {isThemeSelectionModalOpen && <ThemeSelectionModal />}
      {isResumeUploadMOdalOpen && <ResumeUploadModal />}
      {isPhoneVerificationModalOpen && <PhoneVerificationModal />}
      {isSubmitFeedbackOpen && <SubmitFeedbackModal />}
      {isAllUploadsModalOpen && <AllUploadsModal />}
      {isJobShareModalOpen && <JobShareModal />}
      {isResumePDFPreviewModalOpen && <ResumePDFPreviewModal />}
    </>
  )
}
