import styled, { css } from 'styled-components'
import { TABLET_BREAKPOINT } from 'styles/breakpoints'

export const FilterListItemWrapper = styled.div<{
  isMoreFilters?: boolean
  active?: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  width: ${({ isMoreFilters }) => (isMoreFilters ? 'calc(50% - 5px)' : '100%')};
  overflow: hidden;
  background-color: ${({ active, theme }) =>
    active ? theme.highlight : 'transparent'};

  p {
    color: ${({ theme, active }) => (active ? 'white' : theme.primary)};
  }

  svg {
    color: ${({ theme }) => theme.secondary};
    height: 15px;
    margin-right: 10px;
  }

  :hover {
    background-color: ${({ active, theme }) =>
      active ? theme.highlight : theme.background.tertiary};

    p {
      color: ${({ theme, active }) => (active ? 'white' : theme.primary)};
    }
  }

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`

export const SearchFilterListWrapper = styled.div<{ padded?: boolean }>`
  ${({ padded }) =>
    padded &&
    css`
      padding: 10px;
    `};

  position: relative;
`

export const SearchFilterResultsWrapper = styled.div<{
  isMoreFilters?: boolean
}>`
  max-height: 300px;
  padding-bottom: 40px;
  overflow-y: ${({ isMoreFilters }) => (isMoreFilters ? 'hidden' : 'scroll')};
  overflow-x: hidden;

  ${({ isMoreFilters }) =>
    isMoreFilters &&
    css`
      display: flex;
      flex-wrap: wrap;
      max-height: unset;
      justify-content: space-between;
      padding-bottom: 0px;
    `};
`

export const SearchFilterClearWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.background.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer: default;
`
