import { useState, useRef } from 'react'
import {
  SearchFilterButtonWrapper,
  SearchFilterDropdownWrapper,
  SearchFilterDropdown,
  SearchFilterMenu,
  SearchFilterBackground,
} from '../SearchFilterDropdown/styles'
import { FilterListItemWrapper } from '../SearchFilterDropdown/SearchFilterList/styles'
import {
  capitalizeCamelCase,
  capitalizeReplaceUnderscore,
} from 'helpers/string'
import { Wrapper } from 'styles/styleSystem/wrapper'
import { useOutsideAlerter } from 'hooks/useOutsideAlerter'
import { FiChevronDown } from 'react-icons/fi'
import { useTheme } from 'styled-components'
import { Text } from 'components/Text'

/**
 * Dropdown wrapper for text
 * @param {any} options Array of objects with value + label
 * @param {string} label Label text
 * @param {any} onSelect
 * @param {any} sort
 * @param {boolean} leftAlign Left align menu
 * @param {any} activeOption
 * @param {string} size Size of text md/lg/xl etc.
 * @param {boolean} arrow Show arrow on right side
 * @param {boolean} fadeIn Fade in the menu
 * @param {boolean} hoverable Changes onClick to onMouseEnter
 */

export interface TextDropdownProps {
  options?: any
  label?: any
  onSelect?: any
  sort?: any
  leftAlign?: boolean
  activeOption?: any
  size?: string
  arrow?: boolean
  fadeIn?: boolean
  hoverable?: boolean
  children?: any
  dropdownPaddingTop?: string
}

export const TextDropdown: React.FC<TextDropdownProps> = ({
  label,
  options,
  onSelect,
  sort,
  activeOption = null,
  leftAlign = false,
  size = 'md',
  arrow = false,
  fadeIn = false,
  hoverable = false,
  dropdownPaddingTop,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Dropdown
      label={label}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      leftAlign={leftAlign}
      size={size}
      arrow={arrow}
      fadeIn={fadeIn}
      hoverable={hoverable}
      dropdownPaddingTop={dropdownPaddingTop}
    >
      <SearchFilterDropdownWrapper fitWidth>
        <SearchFilterDropdown>
          {children
            ? { ...children }
            : options?.map((option, index) => (
                <FilterListItemWrapper
                  key={index}
                  active={sort === option || option?.value === activeOption}
                  onClick={() => {
                    onSelect(option)
                    setIsOpen(false)
                  }}
                >
                  {typeof option === 'string' ? (
                    <Text noWrap>
                      {option.includes('_')
                        ? capitalizeReplaceUnderscore(option)
                        : capitalizeCamelCase(option)}
                    </Text>
                  ) : (
                    option.label
                  )}
                </FilterListItemWrapper>
              ))}
        </SearchFilterDropdown>
      </SearchFilterDropdownWrapper>
    </Dropdown>
  )
}

const Dropdown = ({
  label,
  children,
  isOpen,
  setIsOpen,
  leftAlign = true,
  arrow,
  size,
  fadeIn,
  hoverable,
  dropdownPaddingTop,
}) => {
  const wrapperRef = useRef()
  const theme = useTheme()

  useOutsideAlerter(wrapperRef, () => {
    setIsOpen(false)
  })

  return (
    <SearchFilterButtonWrapper
      ref={wrapperRef}
      onMouseEnter={() => hoverable && setIsOpen(true)}
      onMouseLeave={() => hoverable && setIsOpen(false)}
    >
      <Wrapper
        cursor="pointer"
        display="flex"
        alignItems="center"
        spacing={10}
        horizontal
        onClick={() => !hoverable && setIsOpen(!isOpen)}
      >
        <Text size={size} color={isOpen ? 'highlight' : 'primary'}>
          {label}
        </Text>
        {arrow && (
          <FiChevronDown color={isOpen ? theme.highlight : theme.secondary} />
        )}
      </Wrapper>
      <SearchFilterMenu isOpen={isOpen} leftAlign={leftAlign} fadeIn={fadeIn}>
        {dropdownPaddingTop && (
          <Wrapper position="absolute" paddingTop={dropdownPaddingTop} />
        )}
        {isOpen && <SearchFilterBackground>{children}</SearchFilterBackground>}
      </SearchFilterMenu>
    </SearchFilterButtonWrapper>
  )
}
