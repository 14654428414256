import { AiOutlineLoading } from 'react-icons/ai'
import { Spin } from 'antd'
import styles from './index.module.css'
import { useTheme } from 'styled-components'

export const LoadingAnimation: React.FC<{ size?: number; color?: string }> = ({
  size = 24,
  color = 'primary',
}) => {
  const theme = useTheme()

  const antIcon = (
    <AiOutlineLoading
      style={{ fontSize: size, color: theme[color] }}
      className={styles.spinner}
    />
  )
  return <Spin indicator={antIcon} />
}
