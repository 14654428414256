import { BaseButtonProps } from '..'
import { RedirectButtonWrapper } from '../RedirectButtonWrapper'
import { PrimaryButton } from '../PrimaryButton'
import _omit from 'lodash-es/omit'

export interface RedirectButtonProps extends BaseButtonProps {
  href: string
  target?: string
}

export const RedirectPrimaryButton: React.FC<RedirectButtonProps> = ({
  label,
  size = 'md',
  withDropdown,
  loading,
  disabled,
  noWrap,
  children,
  icon,
  href,
  target = null,
  ...props
}) => {
  return (
    <RedirectButtonWrapper href={href} target={target}>
      <PrimaryButton
        label={label}
        size={size}
        withDropdown={withDropdown}
        loading={loading}
        disabled={disabled}
        noWrap={noWrap}
        icon={icon}
        {..._omit(props, 'onClick')}
      >
        {children}
      </PrimaryButton>
    </RedirectButtonWrapper>
  )
}
