import styled from 'styled-components'
import { BaseButton } from '../styles'
import { Text, TextProps } from 'components/Text'
import { transition1 } from 'styles/transformation'

export const PrimaryButtonContainer = styled(BaseButton)<{
  brandColors?: { bg: string; text: string }
}>`
  background-color: ${({ theme, disabled, brandColors }) =>
    brandColors
      ? brandColors.bg
      : disabled
      ? theme.background.tertiary
      : theme.highlight};
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid ${({ theme }) => theme.border};

  ${transition1}

  p {
    color: ${({ theme, disabled, brandColors }) =>
      brandColors
        ? brandColors.text
        : disabled
        ? theme.secondary
        : theme.white} !important;
  }

  svg {
    color: white;
  }

  :hover {
    background-color: ${({ theme, brandColors }) =>
      brandColors ? brandColors.bg : theme.highlight};
    filter: brightness(90%);
    box-shadow: 0 4px 20px ${({ theme }) => theme.highlight}20;
  }
`

export const PrimaryButtonText = styled(Text)<
  TextProps & {
    disabled?: boolean
    brandColors?: { bg: string; text: string }
  }
>`
  line-height: 1;
  color: ${({ theme, disabled, brandColors }) =>
    brandColors
      ? brandColors.text
      : disabled
      ? theme.secondary
      : theme.white} !important;
  text-align: center;
`
