import { css } from 'styled-components'
import { transition1 } from './transformation'

export const defaultShadow = css`
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.2);
`

export const cardShadow = css`
  box-shadow: 0 0 26px 10px ${({ theme }) => theme.black}30;
  ${transition1}
  &:hover {
    box-shadow: 5px 5px 26px 10px ${({ theme }) => theme.black}25;
    ${transition1}
  }
`

export const SmallShadowMixin = css`
  box-shadow: 0 0 20px 0px ${({ theme }) => theme.black}10;
  ${transition1}
`

export const LargeShadowMixin = css`
  box-shadow: 0 0 20px 0px ${({ theme }) => theme.black}25;
  ${transition1}
`
